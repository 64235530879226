import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import axios from 'axios';

import App from './App';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import configureStore from './store';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root') 
// );
const { store } = configureStore();

axios.defaults.baseURL =  'https://icvback.herokuapp.com/'

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
