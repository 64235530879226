import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

const rootReducer = (history) =>
	combineReducers({
	

	});
	
export default rootReducer;
