import { applyMiddleware, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
const loggerMiddleware = createLogger();
export const history = createBrowserHistory();
const connectRouterHistory = connectRouter(history);
const rootReducerist = rootReducer(history);

function configureStoreProd(initialState) {
	const reactRouterMiddleware = routerMiddleware(history);
	const middlewares = [ thunk, reactRouterMiddleware ];
	const store = createStore(rootReducerist, initialState, compose(applyMiddleware(...middlewares)));

	return { store };
}

function configureStoreDev(initialState) {
	const reactRouterMiddleware = routerMiddleware(history);
	const middlewares = [
		// Add other middleware on this line...
		thunk,
		reactRouterMiddleware,
		loggerMiddleware
	];

	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
	//const persistedState = loadToken();
	const store = createStore(
		rootReducerist, // root reducer with router state 
		initialState,
		composeEnhancers(applyMiddleware(...middlewares))
	);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('./reducers', () => {
			const nextRootReducer = require('./reducers').default;
			store.replaceReducer(connectRouterHistory(nextRootReducer));
		});
	}

	return { store };
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
